@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap');

html {
  font-family: 'Montserrat', sans-serif;
}

.container {
  width: 800px;
  margin: auto;
}

.tabs {
  margin-top: 50px;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-list {
    text-align: right;
  
    &__item {
      display: inline-block;
      color: gray;
      padding: 15px 25px;
      font-weight: 600;
      text-transform: uppercase;
      text-align: center;
      cursor: pointer;
  
      &.active {
        color: red;
      }
    }
  }

  &-content {
    padding: 50px;
    border: 2px solid #ccc;
    border-radius: 7px;
    display: flex;
    justify-content: space-between;
  }
}

.font-name {
  font-weight: 500;
  padding-left: 20px;
  position: relative;

  &:before {
    position: absolute;
    content: "";
    width: 8px;
    height: 8px;
    background: #aaa;
    top: 4px;
    left: 0px;
    border-radius: 50%;
  }
}

.font-item {
  width: 25%;

  .square {
    display: inline-block;
    width: 100px;
    height: 100px;
    border-radius: 15px;
    background: white;
    padding: 3px;
    border: 1px solid black;

    .letter {
      background: #00A653;
      padding: 3px;
      border-radius: 12px;
      height: calc(100% - 6px);
      font-size: 30px;
      font-weight: bold;
      color: #ffffff40;
      display: flex;
      align-items: flex-end;

      span {
        margin-left: 12px;
        margin-bottom: 10px;
      }

      &.green {
        background: #00A653;
      }

      &.pink {
        background: #FE7FC3;
      }

      &.blue {
        background: #046DFF;
      }
    }
  }

  &:first-child {
    width: 40%;
    
    .square {
      width: 150px;
      height: 150px;
  
      span {
        font-size: 50px;
      }
    }
  }
}

